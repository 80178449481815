/* eslint-disable linebreak-style */
import { Oval } from 'react-loader-spinner';
import React from 'react';

// eslint-disable-next-line import/prefer-default-export
export function LoadingSpinner() {
  return (
    <Oval
      height={80}
      width={80}
      color='#4fa94d'
      wrapperStyle={{}}
      wrapperClass=''
      visible
      ariaLabel='oval-loading'
      secondaryColor='#4fa94d'
      strokeWidth={2}
      strokeWidthSecondary={2}
    />
  );
}
