/* eslint-disable linebreak-style */
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import '../styles/Results.css';
import EventDate from './EventDate';
import { LoadingSpinner } from './Loading';

let today = new Date();
const dd = String(today.getDate() + 1).padStart(2, '0');
const mm = String(today.getMonth() + 1).padStart(2, '0'); // January is 0!
const yyyy = today.getFullYear();

today = `${yyyy}-${mm}-${dd}`;

function BasketballResults() {
  const [isLoaded, setIsLoaded] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    const abortController = new AbortController();
    axios
      .get('https://api-basketball.p.rapidapi.com/games', {
        params: { date: today, league: '12', season: '2022-2023' },
        headers: {
          'x-rapidapi-host': 'api-basketball.p.rapidapi.com',
          'x-rapidapi-key': process.env.REACT_APP_API_KEY,
        },
      })
      .then((res) => {
        setData(res.data.response);
        setIsLoaded(true);
      })
      .catch(() => {
        setIsLoaded(false);
        return abortController.abort();
      });
  }, []);

  const scoreCard = data.map((game) => (
    <div className='score-card' key={game.id}>
      <div className='game-information'>
        <strong>
          <h3 className='league-names'>{game.league.name}</h3>
        </strong>
        <strong>
          <h5 className='countries'>{game.country.name}</h5>
        </strong>
      </div>
      <span className='game-time'>{game.status.long}</span>
      <span className='team1'>{game.teams.home.name}</span>
      <span className='team2'>{game.teams.away.name}</span>
      <span className='team1-result'>{game.scores.home.total}</span>
      <span className='team2-result'>{game.scores.away.total}</span>
    </div>
  ));

  /*
  Here we conditionally render using the ternary operator
  If the data is loaded then we display it, otherwise we have a loading placeholder to inform
  the users the data is still loading. We use the placeholder instead of showing a blank screen */
  return isLoaded === true ? (
    <>
      {' '}
      <EventDate />
      {scoreCard}
    </>
  ) : (
    <LoadingSpinner />
  );
}

export default BasketballResults;
