/* eslint-disable no-else-return */
/* eslint-disable linebreak-style */
/* eslint-disable object-curly-newline */

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import '../styles/Results.css';
import EventDate from './EventDate';
import { LoadingSpinner } from './Loading';

let today = new Date();
const dd = String(today.getDate()).padStart(2, '0');
const mm = String(today.getMonth() + 1).padStart(2, '0'); // January is 0!
const yyyy = today.getFullYear();

today = `${yyyy}-${mm}-${dd}`;

function BaseballResults() {
  // eslint-disable-next-line no-unused-vars
  const [isLoaded, setIsLoaded] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    const abortController = new AbortController();
    axios
      .get('https://api-baseball.p.rapidapi.com/games', {
        params: { league: '1', season: '2022', date: today },
        headers: {
          'X-RapidAPI-Key': process.env.REACT_APP_API_KEY,
          'X-RapidAPI-Host': 'api-baseball.p.rapidapi.com',
        },
      })
      .then((res) => {
        setData(res.data.response);
        setIsLoaded(true);
      })
      .catch((e) => {
        if (e.name === 'AbortError') {
          return abortController.abort();
        }
        setIsLoaded(false);
        throw e;
      });

    return () => {
      abortController.abort();
    };
  }, []);

  const results = data.map((game) => (
    <div className='score-card' key={game.id}>
      <div className='game-information'>
        <strong>
          <h3 className='league-names'>{game.league.name}</h3>
        </strong>
        <strong>
          <h5 className='countries'>{game.league.country}</h5>
        </strong>
      </div>
      <span className='game-time'>{game.status.short}</span>
      <div className='logo team1-logo'>
        <img
          alt='Home team logo'
          src={game.teams.home.logo}
          height='18'
          width='18'
        />
      </div>
      <div className='logo team2-logo'>
        <img
          alt='Away team logo'
          src={game.teams.away.logo}
          height='18'
          width='18'
        />
      </div>
      <span className='team1'>{game.teams.home.name}</span>
      <span className='team2'>{game.teams.away.name}</span>
      <span className='team1-result'>{game.scores.home.total}</span>
      <span className='team2-result'>{game.scores.away.total}</span>
    </div>
  ));

  if (isLoaded === true) {
    if (results.length === 0) {
      return (
        <>
          <EventDate />
          <h2 className='zero-games'>NO GAMES TODAY</h2>
        </>
      );
    }
    return (
      <>
        <EventDate />
        {results}
      </>
    );
  } else {
    return <LoadingSpinner />;
  }
}

export default BaseballResults;
