/* eslint-disable linebreak-style */
const initialState = {
  sport: 'soccer',
};

// eslint-disable-next-line default-param-last
function sportReducer(state = initialState, action) {
  switch (action.type) {
    case 'sport/soccer':
      return { ...state, sport: 'soccer' };
    case 'sport/baseball':
      return { ...state, sport: 'baseball' };
    case 'sport/basketball':
      return { ...state, sport: 'basketball' };
    case 'sport/hockey':
      return { ...state, sport: 'hockey' };
    case 'sport/cricket':
      return { ...state, sport: 'cricket' };
    default:
      return state;
  }
}

export default sportReducer;
