/* eslint-disable linebreak-style */
import Table from 'react-bootstrap/Table';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import '../styles/BaseballStandings.css';

function Standings() {
  // eslint-disable-next-line no-unused-vars
  const [data, setData] = useState([]);
  const [key, setKey] = useState('AL');

  useEffect(() => {
    const abortController = new AbortController();
    axios
      .get('https://api-baseball.p.rapidapi.com/standings', {
        params: { league: '1', season: '2022' },
        headers: {
          'x-rapidapi-key': process.env.REACT_APP_API_KEY,
          'x-rapidapi-host': 'api-baseball.p.rapidapi.com',
        },
      })
      .then((res) => {
        // this index get the regular season standings
        setData(res.data.response[0]);
      })
      .catch((e) => {
        if (e.name === 'AbortError') {
          return abortController.abort();
        }
        throw e;
      });

    return () => {
      abortController.abort();
    };
  }, []);
  // eslint-disable-next-line comma-dangle

  const americanStandings = data.slice(0, 15).map((team) => (
    <tr key={team.team.id} className='team-info'>
      <td className='team-pos'>{team.position}</td>
      <td className='team-name'>{team.team.name}</td>
      <td className='team-win'>{team.games.win.total}</td>
      <td className='team-loss'>{team.games.lose.total}</td>
    </tr>
  ));

  const nationalStandings = data.slice(15, 30).map((team) => (
    <tr key={team.team.id} className='team-info'>
      <td className='team-pos'>{team.position}</td>
      <td className='team-name'>{team.team.name}</td>
      <td className='team-win'>{team.games.win.total}</td>
      <td className='team-loss'>{team.games.lose.total}</td>
    </tr>
  ));

  return (
    <Tabs activeKey={key} onSelect={(k) => setKey(k)} className='table mb-2'>
      <Tab eventKey='AL' title='American League' className='tab-itself'>
        <Table responsive className='standings'>
          <tbody className='table-body'>
            <tr className='standing-info'>
              <th>Position</th>
              <th>Team</th>
              <th>W</th>
              <th>L</th>
            </tr>
            {americanStandings}
          </tbody>
        </Table>
      </Tab>

      <Tab eventKey='NL' title='National League'>
        <Table responsive className='standings'>
          <tbody className='table-body'>
            <tr className='standing-info'>
              <th>Position</th>
              <th>Team</th>
              <th>W</th>
              <th>L</th>
            </tr>
            {nationalStandings}
          </tbody>
        </Table>
      </Tab>
    </Tabs>
  );
}

export default Standings;
