/* eslint-disable linebreak-style */
import { combineReducers, configureStore } from '@reduxjs/toolkit';
import sportReducer from '../reducers/sportReducer';
import slr from '../reducers/soccerLeagueReducer';
import newsReducer from '../reducers/newsReducer';

const rootReducer = combineReducers({
  selectSport: sportReducer,
  soccerLeague: slr,
  newsReducer,
});
const store = configureStore({ reducer: rootReducer });
export default store;
