/* eslint-disable indent */
/* eslint-disable linebreak-style */
import React from 'react';
import '../styles/MainContent.css';
import BasketballResults from './BasketballResults';
import BaseballResults from './Baseball';
import BaseballStandings from './BaseballStandings';
import SoccerResults from './SoccerResults';
import News from './News';
import { LoadingSpinner } from './Loading';
import store from '../store';
import HockeyResults from './Hockey';

function MainContent() {
  switch (store.getState().selectSport.sport) {
    case 'soccer':
      return (
        <div className='main-container'>
          <div className='results'>
            <SoccerResults />
          </div>
          <div className='news-articles'>
            <News />
          </div>
        </div>
      );
    case 'basketball':
      return (
        <div className='main-container'>
          <div className='results'>
            <BasketballResults />
          </div>
          <div className='news-articles'>
            <strong>
              <span>Highlighted News</span>
            </strong>

            <News />
          </div>
        </div>
      );
    case 'baseball':
      return (
        <div className='main-container'>
          <div className='results'>
            <BaseballResults />
          </div>
          <div className='standings'>
            <BaseballStandings />
          </div>
        </div>
      );

    case 'hockey':
      return (
        <div className='main-container'>
          <div className='results'>
            <HockeyResults />
          </div>
        </div>
      );

    default:
      // eslint-disable-next-line react/jsx-indent
      return (
        <div>
          <LoadingSpinner />
        </div>
      );
  }
}

export default MainContent;
