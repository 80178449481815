/* eslint-disable linebreak-style */
import React from 'react';
import { Stack } from 'react-bootstrap';
import '../styles/EventDate.css';

function EventDate() {
  let today = new Date();
  const dd = String(today.getDate()).padStart(2, '0');
  const mm = String(today.getMonth() + 1).padStart(2, '0'); // January is 0!
  const yyyy = today.getFullYear();

  today = ` ${mm}/${dd}/${yyyy}`;
  return (
    <div className='dates'>
      <Stack direction='horizontal' gap={2}>
        {/* <div className="live-feature">Live</div> */}
        <div className='current-date'>
          {/* eslint-disable-next-line react/no-unescaped-entities */}
          Today's date:
          {today}
        </div>
      </Stack>
    </div>
  );
}

export default EventDate;
