/* eslint-disable linebreak-style */
/* eslint-disable object-curly-newline */
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import EventDate from './EventDate';
import { LoadingSpinner } from './Loading';
import '../styles/Results.css';

let today = new Date();
const dd = String(today.getDate()).padStart(2, '0');
const mm = String(today.getMonth() + 1).padStart(2, '0'); // January is 0!
const yyyy = today.getFullYear();

today = `${yyyy}-${mm}-${dd}`;

function SoccerResults() {
  const [isLoaded, setIsLoaded] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    const abortController = new AbortController();

    setIsLoaded(false);
    axios
      .get('https://api-football-v1.p.rapidapi.com/v3/fixtures', {
        params: { date: today },
        headers: {
          'x-rapidapi-host': 'api-football-v1.p.rapidapi.com',
          'x-rapidapi-key': process.env.REACT_APP_API_KEY,
        },
      })
      .then((res) => {
        setData(res.data.response);
        setIsLoaded(true);
      })
      .catch(() => {
        setIsLoaded(false);
        return abortController.abort();
      })
      .finally((error) => {
        // eslint-disable-next-line no-console
        console.error(error);
      });
  }, []);
  const scoreCard = data.map((game) => (
    <div className='score-card' key={game.fixture.id}>
      <div className='game-information'>
        <strong>
          <h3 className='league-names'>{game.league.name}</h3>
        </strong>
        <strong>
          <h5 className='countries'>{game.league.country}</h5>
        </strong>
      </div>
      <span className='game-time'>{game.fixture.status.long}</span>
      <div className='logo team1-logo'>
        <img
          alt='Home team logo'
          src={game.teams.home.logo}
          height='18'
          width='18'
        />
      </div>
      <div className='logo team2-logo'>
        <img
          alt='Away team logo'
          src={game.teams.away.logo}
          height='18'
          width='18'
        />
      </div>
      <span className='team1'>{game.teams.home.name}</span>
      <span className='team2'>{game.teams.away.name}</span>
      <span className='team1-result'>{game.goals.home}</span>
      <span className='team2-result'>{game.goals.away}</span>
    </div>
  ));

  return isLoaded === true ? (
    <>
      <EventDate />
      {scoreCard}
    </>
  ) : (
    <LoadingSpinner />
  );
}

export default SoccerResults;
