/* eslint-disable linebreak-style */
import React from 'react';
import { useSelector } from 'react-redux';
import Header from './Header';
import MainContent from './MainContent';

function App() {
  const sport = useSelector((state) => state.selectSport.sport);

  return (
    <div className='app'>
      <section>
        <Header sport={sport} />
      </section>
      <section>
        <MainContent selected={sport} />
      </section>
      <footer>
        Copyright &copy; 2022-2023 ProGameScore
        <div className='footer-links'>
          <span> Privacy Notice</span>
          <span>Cookie Policy</span>
          <span>Contact</span>
          <span>Terms of Use</span>
        </div>
      </footer>
    </div>
  );
}

export default App;
