/* eslint-disable linebreak-style */
// import { Container, Navbar, Nav } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import '../styles/Header.css';
import { React } from 'react';

function changeActive() {
  const linksContainer = document.getElementById('links-bar');

  // Get all buttons with class="btn" inside the container
  const links = linksContainer.getElementsByClassName('link');

  // Loop through the buttons and add the active class to the current/clicked button
  // eslint-disable-next-line no-const-assign, no-plusplus
  for (let i = 0; i < links.length; i++) {
    // eslint-disable-next-line func-names
    links[i].addEventListener('click', function () {
      // eslint-disable-next-line prefer-const
      let current = document.getElementsByClassName('active');
      current[0].className = current[0].className.replace(' active', '');
      this.className += ' active';
    });
  }
}

function Header() {
  const dispatch = useDispatch();
  return (
    <div className='header-container'>
      <h1 className='app-title'>
        <strong>
          <a
            id='home'
            href='#home'
            onClick={() => {
              dispatch({ type: 'sport/soccer' });
            }}
          >
            PRO GAME SCORE
          </a>
        </strong>
      </h1>
      <div id='links-bar'>
        <span>
          <a
            className='link active'
            href='#soccer'
            onClick={() => {
              dispatch({ type: 'sport/soccer' });
              changeActive();
            }}
          >
            Soccer
          </a>
        </span>
        <span>
          <a
            className='link'
            href='#basketball'
            onClick={() => {
              dispatch({ type: 'sport/basketball' });
              changeActive();
            }}
          >
            Basketball
          </a>
        </span>
        <span>
          <a
            className='link'
            href='#baseball'
            onClick={() => {
              dispatch({ type: 'sport/baseball' });
              changeActive();
            }}
          >
            Baseball
          </a>
        </span>
        <span>
          <a
            className='link'
            href='#hockey'
            onClick={() => {
              dispatch({ type: 'sport/hockey' });
              changeActive();
            }}
          >
            Hockey
          </a>
        </span>
        {/* <span>
          <a
            className='link'
            href='#cricket'
            onClick={() => {
              dispatch({ type: 'sport/cricket' });
              changeActive();
            }}
          >
            Cricket
          </a>
        </span> */}
      </div>
    </div>
  );
}

export default Header;
