/* eslint-disable linebreak-style */
const initialState = {
  news_selected: { id: '2021020913320920836', sport: 'soccer' },
};

// eslint-disable-next-line default-param-last
function newsReducer(state = initialState, action) {
  switch (action.type) {
    case 'sport/soccer':
      return {
        ...state,
        news_selected: { id: '2021020913320920836', sport: 'soccer' },
      };
    case 'sport/baseball':
      return { ...state, news_selected: 'baseball' };
    case 'sport/basketball':
      return {
        ...state,
        news_selected: { id: '2021020913321516170', sport: 'basketball' },
      };
    case 'sport/hockey':
      return {
        ...state,
        news_selected: { id: '2021020913321411486', sport: 'hockey' },
      };
    case 'sport/cricket':
      return {
        ...state,
        news_selected: { id: '2021020913321411486', sport: 'cricket' },
      };
    default:
      return state;
  }
}

export default newsReducer;
