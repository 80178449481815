/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable comma-dangle */
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { LoadingSpinner } from './Loading';
import store from '../store';
import '../styles/News.css';

function News() {
  const selectedNews = store.getState().newsReducer.news_selected.id;

  const [isLoaded, setIsLoaded] = useState(false);
  const [data, setData] = useState([]);
  const [error, setError] = useState();
  useEffect(() => {
    const abortController = new AbortController();
    setIsLoaded(false);

    axios
      .get('https://livescore6.p.rapidapi.com/news/v2/list-by-sport', {
        params: {
          category: selectedNews,
          page: '1',
        },
        headers: {
          'x-rapidapi-host': 'livescore6.p.rapidapi.com',
          'x-rapidapi-key': process.env.REACT_APP_API_KEY,
        },
      })
      .then((res) => {
        setData(res.data.data);
        setIsLoaded(true);
      })
      .catch(() => {
        setIsLoaded(false);
        return abortController.abort();
      })
      .finally((e) => {
        setError(e);
      });
  }, [selectedNews]);

  const news = data.slice(0, 4).map((article) => (
    <div className='article' key={article.id}>
      <img
        className='article-img'
        src={article.image.data.urls.uploaded.thumbnail}
        alt={article.seo.title}
      />
      <span className='thumbnail-text'> {article.title} </span>
    </div>
  ));

  return isLoaded === true ? (
    <div>
      {news}
      {error}
    </div>
  ) : (
    <LoadingSpinner />
  );
}

export default News;
