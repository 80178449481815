/* eslint-disable linebreak-style */
const initialState = {
  league: 39,
  name: 'Premier League',
};

// eslint-disable-next-line default-param-last
function soccerLeagueReducer(state = initialState, action) {
  switch (action.type) {
    case 'league/premier-league':
      return { ...state, league: 39, name: 'Premier League' };
    case 'league/ligue-1':
      return { ...state, sport: 61, name: 'Ligue 1' };
    case 'league/la-liga':
      return { ...state, sport: 140, name: 'La Liga' };
    case 'league/bundesliga':
      return { ...state, sport: 78, name: 'Bundesliga' };
    case 'league/serie-a':
      return { ...state, sport: 135, name: 'Serie-A' };
    default:
      return state;
  }
}

export default soccerLeagueReducer;
